header {
    display: flex;
    justify-content: space-between;
    background-color: #66b3ff;
    padding: 12px;
    color: white;

}

header h1 {
    font-size: 1.5em;
    font-weight: 400;
}

header a {
    text-decoration: none;
    color: rgb(84, 66, 66);
}

.search {
    display: flex;
}

.search input {
    width: 12em;
    margin-right: 5px;
}


