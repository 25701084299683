* {
    box-sizing: border-box;
}

.add-form {
    width: 800px;
    margin: 50px auto;
}

.add-form label {
    display: block;
    margin-top: 10px;
    font-weight: bold;
    margin-bottom: 25px;
}

.add-form input, .add-form textarea{
    width: 100%;
    margin-top: 20px;
    padding: 20px 0;
}

.add-form small {
    font-weight: normal;
}

.add-form button {
    display: block;
    padding: 10px;
    width: 100%;
    margin: 0 auto;
}
