.btn {
    text-decoration: none;
    color: rgb(84, 66, 66);
    font-size: 0.8rem;
    border: none;
    background-color: #9494b8;
    text-transform: uppercase;
    padding: 0.5em 3em;
    border-radius: 4px;
    font-weight: 600;
}

